<template>
    <div id="landing-page">
        <div v-if="home.loading" id="loader">
            <spinner-eclipse :width="120" />
        </div>
        <div v-if="!home.loading">
            <component :is="svgComponent" />
            <Header />
            <Carousel @playPopupVideo="playPopupVideo" />
            <Features />
            <Screenshots />
            <Promos />
            <Footer />
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import Header from '@/components/home/Header'
    import Carousel from '@/components/home/Carousel'
    import Features from '@/components/home/Features'
    import Screenshots from '@/components/home/Screenshots'
    import Promos from '@/components/home/Promos'
    import Footer from '@/components/customer/Footer'

    import SpinnerEclipse from '@/components/SpinnerEclipse'
    import {mapState,mapActions,mapGetters} from 'vuex';

    import eventBus from '@/libs/eventBus'

    export default {
        components: {
            Header,
            Carousel,
            Features,
            Screenshots,
            Promos,
            Footer,
            SpinnerEclipse,
        },
        watch: {
            '$route' (to, from) {
                from
                to

                if (this.$route.name !== undefined) {
                    const menu_open = window.$('.offcanvas.canvas-slid').length

                    if (menu_open) {
                        setTimeout(()=> {
                            this.scrollToPosition(this.$route.name)
                        }, 500)
                    } else {
                        this.scrollToPosition(this.$route.name)
                    }
                }
            }
        },
        methods: {
            ...mapActions(['homePageApiRequest','refreshActivity']),
            scrollToPosition(id) {
                const feature = window.$(`#${id}`)
                window.scrollTo({top: feature.position().top -100, behavior: 'smooth'})
            },
            playPopupVideo(src, name) {
                eventBus.$emit('loadPopupVideoSrc', {
                    src: src,
                    name: name
                })
            }
        },
        computed: {
            ...mapState(['svg', 'home']),
            ...mapGetters(['getCSS']),
            svgComponent() {
                try {
                    return defineAsyncComponent(() => import(`@/components/svg/${this.svg.location}`))
                } catch (e) {
                    return null
                }
            }
        },
        mounted() {
            if (!this.home.loading && this.$route.path !== '/') {
                if (this.$route.name !== undefined) {
                    this.scrollToPosition(this.$route.name)
                }
            }
        },
        created() {
            this.refreshActivity()
            this.homePageApiRequest()
        }
    }
</script>

<style lang="scss">
    @import '~@/assets/scss/landing/style.scss';
    @import '~@/assets/fonts/flaticon/flaticon.css';

    .video-popup {
        position: absolute;
        width: 100%;
        z-index: 100;
    }

    footer {
        position: relative !important;
    }
</style>