<template>
    <transition name="fade" mode="out-in">
        <div>
            <section id="intro-hero" :class="`${carousel[carousel_index].class} ${carousel_fade}`">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 hero-text">
                            <h1 class="hero-title" v-html="carousel[carousel_index].title"></h1>

                            <div class="div-hero-tagline">
                                <p class="hero-tagline" v-html="carousel[carousel_index].tagline"></p>
                            </div>

                            <div v-if="!session.logged_in && !isSSO" class="intro-btn">
                                <a class="btn btn-theme btn-sm btn-round primary-green" href="/auth/register">{{ commonTranslations['registration-submit-btn'] }}</a>
                            </div>
                        </div>
                        <div class="col-lg-5" v-if="getVideos.introduction.length > 0">
                            <div class="thumbVideo pull-right">
                                <a @click="$emit('playPopupVideo', getVideos.introduction, 'introduction')">
                                    <div class="video">
                                        <div class="bg"></div>
                                        <img src="/assets/img/video-thumnbail.png">

                                        <svg class="flag-icon"><use xlink:href="#play-button"></use></svg>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </transition>
</template>

<script>
    import {mapState, mapGetters} from 'vuex';

    export default {
        data() {
            return {
                carousel_index: 0,
                carousel_fade: 'carousel-fade-in'
            }
        },
        computed: {
            ...mapState(['session']),
            ...mapGetters(['landingTranslations', 'commonTranslations','getVideos', 'isSSO']),
            carousel() {
                return [
                    {
                        class: 'bg-image-1',
                        show: true,
                        title: this.landingTranslations['banner-image-1-title'],
                        tagline: this.landingTranslations['banner-image-1-description']
                    },
                    {
                        class: 'bg-image-2',
                        show: false,
                        title: this.landingTranslations['banner-image-2-title'],
                        tagline: this.landingTranslations['banner-image-2-description']
                    }
                ];
            }
        },
        methods: {
            popupModalVideo() {
                window.$('#video-modal').modal('show')
            }
        },
        created() {
            setInterval(()=> {
                this.carousel_fade = 'carousel-fade-out'

                setTimeout(()=> {
                    switch (this.carousel_index) {
                        case 0:
                            this.carousel_index = 1
                            break;
                        case 1:
                            this.carousel_index = 0
                            break;
                    }

                    this.carousel_fade = 'carousel-fade-in'
                }, 500)
            }, 15000)
        }
    }
</script>

<style scoped lang="scss">
    .div-hero-tagline {
        border-left: 2px solid $base-color !important;
    }

    #intro-hero {
        margin-top: 70px;
    }

    .thumbVideo .video .bg {
        height: 257.719px;
    }

    .thumbVideo .video img {
        width: 100%;
    }

    .thumbVideo .video svg {
        fill: $base-color !important;
        opacity: 1;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 1s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .carousel-fade-out {
        opacity: 1;
        animation-name: fadeOut;
        animation-duration: .5s;
        animation-fill-mode: forwards;
    }

    .carousel-fade-in {
        animation: fadeIn .5s;
        opacity: 1;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
</style>