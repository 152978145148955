<template>
    <section id="features">
        <div :class="{ container: container }">
            <div class="row mb-lg-4">
                <div class="col-sm-12 text-center">
                    <h2 class="section-title">{{ landingTranslations['features-section-title'] }}</h2>
                    <p class="section-tagline">{{ landingTranslations['features-section-tagline'] }}</p>
                </div>
            </div>

            <div class="row col-md-12">
                <div v-bind:key="index" 
                     v-for="(block, index) in home.features.blocks"
                     class="text-center mt-5 wow fadeInUp"
                     :class="column"
                     v-show="block.enabled"
                     :data-wow-duration="block.duration" 
                     :data-wow-delay="block.delay">
                    <div class="feature-block d-flex flex-column">
                        <span class="icon-block bg-blue">
                            <i v-if="block.icon.type === 'i'" :class="block.icon.name"></i>
                            <svg v-if="block.icon.type === 'svg'" class="flag-icon"><use :href="`#${block.icon.name}`"></use></svg>
                        </span>
                        <h4 v-html="block.title"></h4>
                        <p v-html="block.description"></p>
                        <router-link v-if="block.promo" :to="`/features/${block.id}`" class="btn btn-sm btn-theme primary-pink btn-round">
                            {{ landingTranslations['learn-more-txt'] }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import $ from 'jquery';
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

    export default {
        data() {
            return {
                container: true,
                column: 'col-md-3'
            }
        },
        mounted() {
            let numbers = [], titles_numbers = [];

            setTimeout(()=> {
                $('.feature-block h4').each(function() {
                    titles_numbers.push($(this).height());
                });

                let largestTitleNumbers = Math.max.apply(Math, titles_numbers)

                $('.feature-block h4').css('height', largestTitleNumbers);

                $('.feature-block').each(function(){
                    numbers.push($(this).height());
                });

                let largestNumber = Math.max.apply(Math, numbers);
                
                $('.feature-block').css('height', largestNumber);
            }, 500)
        },
        computed: {
            ...mapState(['home', 'configuration', 'api']),
            ...mapGetters([
                'landingTranslations',
                'languageTranslations',
                'enabledProducts',
                'isProductEnabled'
            ])
        },
        methods: {
            ...mapActions(['homePageApiRequest']),
            ...mapMutations(['updateFeatureBlocks']),
            scrollToAnchorPoint(refName) {
                const el = this.$refs[refName]
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth' })
                }
            },
            createBlocks() {
                if (!this.enabledProducts) {
                    this.homePageApiRequest()
                }

                let online_resources = false,
                    online_resources_promo = [],
                    id_monitoring = [],
                    pc_laptop = [],
                    mobile_protection = [],
                    assistance = []

                Object.keys(this.languageTranslations.landing).forEach(key => {
                    if (key.includes('features-online-resources-title') && this.landingTranslations[key].length > 0) {
                        online_resources = true
                    }

                    if (key.includes('features-online-resources-description-point-')) {
                        online_resources_promo.push(this.landingTranslations[key])
                    }

                    if (key.includes('features-id-monitoring-description-point-')) {
                        id_monitoring.push(this.landingTranslations[key])
                    }

                    if (key.includes('features-pc-laptop-description-point-')) {
                        pc_laptop.push(this.landingTranslations[key])
                    }

                    if (key.includes('features-mobile-protection-description-point-')) {
                        mobile_protection.push(this.landingTranslations[key])
                    }

                    if (key.includes('features-assistance-description-point-')) {
                        assistance.push(this.landingTranslations[key])
                    }
                })


                let features_blocks = []

                features_blocks.push(
                    {
                        id: 'identity-monitoring',
                        enabled: this.isProductEnabled('dms'),
                        icon: {type: 'i', name: 'flaticon-globe'},
                        title: this.landingTranslations['features-id-monitoring-title'],
                        duration: '1s',
                        delay: '.1s',
                        description: this.landingTranslations['features-id-monitoring-text'],
                        promo: {
                            image: `${this.api.cdn}${this.configuration.features.globe}`,
                            description: this.landingTranslations['features-id-monitoring-description-text'],
                            points: id_monitoring
                        }
                    },
                    {
                        id: 'pc-laptop',
                        enabled: this.isProductEnabled('odp'),
                        icon: {type: 'i', name: 'flaticon-laptop-1'},
                        title: this.landingTranslations['features-pc-laptop-title'],
                        duration: '1s',
                        delay: '.3s',
                        description: this.landingTranslations['features-pc-laptop-text'],
                        promo: {
                            image: `${this.api.cdn}${this.configuration.features.laptop}`,
                            description: this.landingTranslations['features-pc-laptop-description-text'],
                            points: pc_laptop
                        }
                    },
                    {
                        id: 'mobile-protection',
                        enabled: this.isProductEnabled('mps'),
                        icon: {type: 'i', name: 'flaticon-smartphone-1'},
                        title: this.landingTranslations['features-mobile-protection-title'],
                        duration: '1s',
                        delay: '.5s',
                        description: this.landingTranslations['features-mobile-protection-text'],
                        promo: {
                            image: `${this.api.cdn}${this.configuration.features.smartphone}`,
                            description: this.landingTranslations['features-mobile-protection-description-text'],
                            points: mobile_protection
                        }
                    },
                    {
                        id: 'assistance',
                        enabled: this.isProductEnabled('online-support'),
                        icon: {type: 'i', name: 'flaticon-telemarketer'},
                        title: this.landingTranslations['features-assistance-title'],
                        duration: '1s',
                        delay: '.7s',
                        description: this.landingTranslations['features-assistance-text'],
                        promo: {
                            image: `${this.api.cdn}${this.configuration.features.telemarketer}`,
                            description: this.landingTranslations['features-assistance-description-text'],
                            points: assistance
                        }
                    }
                )

                if (online_resources) {
                    features_blocks.push({
                        id: 'online-resources',
                        enabled: true,
                        icon: {type: 'svg', name: 'articles'},
                        title: this.landingTranslations['features-online-resources-title'],
                        duration: '1s',
                        delay: '.1s',
                        description: this.landingTranslations['features-online-resources-text'],
                        promo: {
                            image: `${this.api.cdn}${this.configuration.features.article}`,
                            description: this.landingTranslations['features-online-resources-description-text'],
                            points: online_resources_promo
                        }
                    })
                }

                let count = 1,
                    blocks_count = 0;

                features_blocks.forEach(value => {
                    value.delay = `.${count}s`

                    count += 2

                    if (value.enabled) {
                        blocks_count += 1
                    }
                })

                switch(blocks_count) {
                    case 2:
                        this.column = 'col-md-6'
                        break
                    case 3:
                        this.column = 'col-md-4'
                        break
                    case 5:
                        this.column = 'col-md-2'
                        this.container = false
                        break
                    
                    default:
                        this.column = 'col-md-3'
                }

                return features_blocks
            }
        },
        created() {
            this.updateFeatureBlocks(this.createBlocks());
        }
    }
</script>

<style lang="scss">
    #features .btn-theme {
        padding: 7px 30px;
        font-weight: normal;
    }

    #features .col-md-12 {
        padding-right: 0;
    }

    #features .icon-block svg {
        fill: #FFFFFF;
        width: 43px;
        height: 43px;
    }

    @media (min-width: 768px) {
        #features .col-md-2 {
            flex: 0 0 20% !important;
            max-width: 20% !important;
        }
    }
</style>
