import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/home/App.vue'
import PageNotFound from '../views/PageNotFound.vue'

import nextFactory from '@/router/middleware/factory'
import {dpsGuard, mpsGuard} from '@/router/middleware/products'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
            {
                path: '/features/identity-monitoring',
                name: 'identity-monitoring',
                component: Home,
            },
            {
                path: '/features/pc-laptop',
                name: 'pc-laptop',
                component: Home,
                meta: {
                    middleware: [dpsGuard]
                }
            },
            {
                path: '/features/mobile-protection',
                name: 'mobile-protection',
                component: Home,
                meta: {
                    middleware: [mpsGuard]
                }
            },
            {
                path: '/features/assistance',
                name: 'assistance',
                component: Home,
            },
            {
                path: '/features/online-resources',
                name: 'online-resources',
                component: Home,
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        component: PageNotFound
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const {$cookies, $store} = router.app.config.globalProperties
    const auth_token = $cookies.get('auth_token')

    if (auth_token !== undefined && auth_token !== null) {
        $store.dispatch('sessionLoggedIn', true)
    } else {
        $store.dispatch('sessionLoggedIn', false)
    }

    if (to.meta.middleware) {
        const middleware = to.meta.middleware
        const context = {from, next, router, to}
        const nextMiddleware = nextFactory(context, middleware, 1)

        return middleware[0]({
            ...context,
            next: nextMiddleware
        })
    }

    next()
})

export default router
