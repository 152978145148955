<template>
  <div id="landingSideMenu" class="navmenu navmenu-default navmenu-fixed-left offcanvas" role="navigation">
        <a class="navmenu-brand" href="#">Menu</a>
        <ul class="nav navmenu-nav">
            <li v-if="!$store.state.session.logged_in" class="active"><a href="/auth/login" target="_self"><svg class="flag-icon"><use xlink:href="#user-1"></use></svg>{{ commonTranslations['sign-in'] }}</a></li>
            <li v-if="$store.state.menu.dashboard.active" class="active"><a :href="$store.state.menu.dashboard.link" target="_self"><svg class="flag-icon"><use :href="`#${$store.state.menu.dashboard.icon}`"></use></svg>{{ commonTranslations['menu-home'] }}</a></li>

            <li class="features" v-if="isProductEnabled('dms')">
                <router-link tag="a" data-toggle="offcanvas" data-target="#landingSideMenu" to="/features/identity-monitoring">
                    <svg class="flag-icon"><use xlink:href="#globe"></use></svg>
                    <span data-ng-bind-html="feature.title" class="ng-binding">{{ landingTranslations['features-id-monitoring-title'] }}</span>
                </router-link>
            </li>
            <li class="features" v-if="isProductEnabled('odp')">
                <router-link tag="a" data-toggle="offcanvas" data-target="#landingSideMenu" to="/features/pc-laptop">
                    <svg class="flag-icon"><use xlink:href="#laptop-1"></use></svg>
                    <span data-ng-bind-html="feature.title" class="ng-binding">{{ landingTranslations['features-pc-laptop-title'] }}</span>
                </router-link>
            </li>
            <li class="features" v-if="isProductEnabled('mps')">
                <router-link tag="a" data-toggle="offcanvas" data-target="#landingSideMenu" to="/features/mobile-protection">
                    <svg class="flag-icon"><use xlink:href="#smartphone-1"></use></svg>
                    <span data-ng-bind-html="feature.title" class="ng-binding">{{ landingTranslations['features-mobile-protection-title'] }}</span>
                </router-link>
            </li>
            <li class="features" v-if="isProductEnabled('online-support')">
                <router-link tag="a" data-toggle="offcanvas" data-target="#landingSideMenu" to="/features/assistance">
                    <svg class="flag-icon"><use xlink:href="#telemarketer"></use></svg>
                    <span data-ng-bind-html="feature.title" class="ng-binding">{{ landingTranslations['features-assistance-title'] }}</span>
                </router-link>
            </li>

            <li v-show="menu.active && (index !== 'dashboard')" class="active" :key="index" v-for="(menu, index) in $store.state.menu">
                <a :href="menu.link"><svg class="flag-icon"><use :href="`#${menu.icon}`"></use></svg>{{ menu.name }}</a>
                <ul v-show="menu.dropdown.length > 0">
                    <li v-show="dropdown.link.length > 0" :key="key" v-for="(dropdown, key) in menu.dropdown">
                        <a v-if="index !== 'videos'" :href="dropdown.link" :target="dropdown.target">
                            <svg class="flag-icon"><use :href="`#${dropdown.icon}`"></use></svg>
                            {{ dropdown.name }}
                        </a>
                        <a v-else @click="$emit('openPopupVideoWithSource', dropdown.link, dropdown.name)" :target="dropdown.target">
                            <svg class="flag-icon"><use :href="`#${dropdown.icon}`"></use></svg>
                            {{ dropdown.name }}
                        </a>
                    </li>
                </ul>
            </li>

            <li class="active" v-if="availableLanguages.length > 1">
                <a><svg class="flag-icon"><use :href="`#${activeLanguage.icon}`"></use></svg>{{ activeLanguage.name }}</a>
                <ul>
                    <li v-bind:key="key" v-for="(language, key) in dropdownLanguages">
                        <a class="nav-link" @click="languageChanged(language.alias)">
                            <svg class="flag-icon"><use :href="`#${language.icon}`"></use></svg>
                                {{ language.name }}
                            </a>
                    </li>
                </ul>
            </li>

            <li v-if="!isSSO" class="col-md-12 register-btn"><a class="nav-link scrolling btn-theme btn-round btn-menu" data-toggle="offcanvas" data-target="#landingSideMenu" href="/auth/register" target="_self">{{ commonTranslations['registration-submit-btn'] }}</a></li>
        </ul>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        methods: {
            ...mapMutations(['updateLanguageIntent']),
             languageChanged(language) {
                this.updateLanguageIntent(language);
                location.reload();
            }
        },
        computed: {
            ...mapGetters([
                'commonTranslations', 
                'landingTranslations', 
                'availableLanguages', 
                'activeLanguage', 
                'dropdownLanguages',
                'isProductEnabled',
                'isSSO']),
        }
    }
</script>

<style lang="scss" scoped>
    .navmenu, .navbar-offcanvas {
        height: auto !important;    
    }

    .navmenu-fixed-left {
        position: fixed;
        height: auto;
        width: 300px;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        border-width: 0 1px 0 0;
    }

    ul.navmenu-nav li ul,
    ul.navmenu-nav li ul li {
        list-style-type: none;
        margin: 0 !important;
    }

    ul.navmenu-nav li ul li,
    ul.navmenu-nav > li > ul > li > a {
        font-weight: 300;
        font-size: 15px;
        color: #777;
        padding: 0;
        margin: 0;
    }

    ul.navmenu-nav > li > ul > li svg {
        left: -25px;
    }

    ul.navmenu-nav li.register-btn {
        margin-top: 20px;
    }

    ul.navmenu-nav li.register-btn a {
        color: #FFF;
        padding-left: 20px !important;
    }
</style>

