<template>
    <section id="screenshot">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="swiper-pagination"></div>
                    <div class="swiper-container screenshots-gallery">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-bind:key="index" v-for="(screenshot, index) in screenshots">
                                <a :href="`${api.assets_url}/${screenshot.image}`">
                                    <img :src="`${api.assets_url}/${screenshot.image}`" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-success description col-sm-6 col-sm-offset-3" v-html="screenshot_description"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Swiper from "swiper/bundle";
    import $ from 'jquery'
    import {mapState, mapGetters} from 'vuex';

    export default {
        data() {
            return {
                screenshots: [],
                screenshot_description: ''
            }
        },
        computed: {
            ...mapState(['configuration', 'api']),
            ...mapGetters(['landingTranslations', 'activeLanguage'])
        },
        methods: {
            createScreenShots() {
                let screenshots = []

                Object.keys(this.configuration.screens[this.activeLanguage.alias]).forEach((key) => {
                    screenshots.push({
                        image: this.configuration.screens[this.activeLanguage.alias][key],
                        description: this.landingTranslations[this.configuration.screens.descriptions[key]]
                    })
                })

                return screenshots
            }
        },
        mounted() {
            var slidesPerView = 2;

            if ($(window).width() < 755) {
                slidesPerView = 1;
            }

            const swiper = new Swiper('.swiper-container', {
                on: {
                    init: function () {}
                },
                pagination          : {
                    el: '.swiper-pagination'
                },
                effect              : 'coverflow',
                parallax            : true,
                spaceBetween        : 0,
                loop                : true,
                grabCursor          : true,
                paginationClickable : true,
                centeredSlides      : true,
                slidesPerView       : slidesPerView,
                coverflow: {
                    rotate      : 60,
                    stretch     : 0,
                    depth       : 0,
                    modifier    : 1,
                    slideShadows: true
                },
                autoplay            : true
            });

            swiper.on('transitionEnd', (index)=> {
                this.screenshot_description = this.screenshots[index.realIndex].description
            });

            $('.screenshots-gallery').magnificPopup({
                delegate: 'a',
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: false,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                gallery: {
                    enabled: true
                },
                zoom: {
                    enabled: true,
                    duration: 300,
                    opener: function(element) {
                        return element.find('img');
                    }
                }
            });
        },
        created() {
            this.screenshots = this.createScreenShots()
            this.screenshot_description  = this.screenshots[0].description
        }
    }
</script>

<style lang="scss">
    .swiper-pagination-bullet-active {
        background: $base-color !important;
    }

    .description {
        margin: 40px auto 0 auto !important;
        text-align: center;
    }
</style>