require('magnific-popup');

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue3-cookies'

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

export const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueCookies)
    .use(Vue3VideoPlayer)

app.mount('#app')

router.app = app