<template>
    <div v-bind:key="featureKey" v-for="(feature, featureKey) in home.features.blocks">
        <section v-if="feature.promo" :id="feature.id" :class="`promo ${feature.bg_gray}`">
            <svg class="up-arrow" v-if="$route.params.features === feature.id" @click="scrollToFeatures"><use :href="`#up-arrow`"></use></svg>

            <div class="container" style="position: relative;">
                <div class="row">
                    <div :class="`col-lg-5 promo-thumb ${feature.position === 'right' ? 'flex-lg-last' : ''}`">
                        <img :src="feature.promo.image" alt="">
                    </div>
                    <div class="col-lg-7">
                        <div class="promo-text">
                            <h2 class="section-title mb-4" v-html="feature.title"></h2>
                            <p v-html="feature.promo.description"></p>

                            <div class="promo-list mt-4">
                                <ul>
                                    <li v-show="point.length !== 0" class="wow fadeIn" v-bind:key="pointKey" v-for="(point, pointKey) in feature.promo.points">
                                        <svg><use :href="`#tick_1`"></use></svg>
                                        {{ point }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        computed: {
            ...mapState(['home'])
        },
        methods: {
            scrollToFeatures() {
                const features = window.$('#features')

                window.scrollTo({top: features.position().top + 100, behavior :'smooth'})

                this.$router.push('/')
            }
        },
        created() {
            let count = 0;

            this.home.features.blocks.forEach((value)=> {
                value.position = 'left'
                value.bg_gray = ''

                if (value.promo) {
                    if (count % 2 == 0) {
                        value.position = 'right'
                        value.bg_gray = 'bg-gray'
                    }

                    count += 1
                }
            })
        }
    }
</script>
<style scoped lang="scss">
    section {
        position: relative;
    }

    svg.up-arrow {
        position: absolute;
        top: 40px;
        left: 10px;
        cursor: pointer;
        width: 80px;
        height: 80px;
        fill: $primary-pink;
        z-index: 1;
    }

    .promo-list li {
        position: relative;
    }

    svg {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 25px;
        height: 25px;
    }
</style>